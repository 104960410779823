import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { CLASSTING_TOKEN, CLASSTING_USER } from '~/constant';
import { useShopStore } from '~/domains';
import {
  isApp,
  appJsCall,
  setLocalStorage,
  getLocalStorage,
  getClayfulToken,
} from '~/utils';

interface AuthenticationPropTypes {
  children: React.ReactNode;
}

const Authentication: React.FC<AuthenticationPropTypes> = observer(({ children }) => {
  const { account } = useShopStore();

  useEffect(() => {
    const clayfulToken = getClayfulToken();

    if(!account.clayfulToken && clayfulToken) {
      account.setClayfulToken(clayfulToken);
    }

    if (isApp) {
      window.setToken = (token = '') => {
        setLocalStorage(CLASSTING_TOKEN, token);
        account.setClasstingToken(token);
      };

      window.setUserAndToken = (user: string) => {
        appJsCall('authorize?callback=setToken');
        setLocalStorage(CLASSTING_USER, user);
      };
      appJsCall('getUser?callback=setUserAndToken');
    } else {
      account.setClasstingToken(getLocalStorage(CLASSTING_TOKEN) ?? 'c_token');
    }
  }, []);

  return (
    <>
      { account.classtingToken && children }
    </>
  );
});

export default Authentication;

import React, { useEffect, useRef } from 'react';
import Router, { useRouter } from 'next/router';
import type { AppProps  } from 'next/app';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/node';
import 'mobx-react-lite/batchingForReactDom';

import { Authentication } from '~/components/Authentication';
import {
  init as initGA,
  overrideGoBackForWebView,
  requestConfigForWebView,
  trackPageViewed,
} from '~/utils';
import { version } from '~/package.json';
import { Provider, useStore } from '~/domains';
import globalStyles from '~/styles/global';
import { theme } from '~/styles/theme';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: `store-service@${version}`,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.SENTRY_ENV,
});

const GA_ID = process.env.GA_ID;
if (GA_ID) initGA(GA_ID);

interface AppPropTypes extends AppProps {
  err: Error;
}

const App: React.FC<AppPropTypes> = ({ Component, pageProps, err }) => {
  const router = useRouter();
  const prevUrl = useRef(router.asPath);
  const store = useStore(router, pageProps.initialState);

  useEffect(() => {
    overrideGoBackForWebView(store.view.goBack);
    requestConfigForWebView({ enableToControlHardwareBackKeyOnWeb: true });
    trackPageViewed();
    Router.events.on('beforeHistoryChange', (url) => {
      trackPageViewed(url, prevUrl.current);
      prevUrl.current = url;
    });

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider value={store}>
      <style jsx global>
        {globalStyles}
      </style>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Authentication>
          <Component {...pageProps} err={err} />
        </Authentication>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
